html {
  background-color: #141414;
}

.text-silver-chalice {
  color: #acacac;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ant-form-item {
  margin-bottom: 8px;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d2d2d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background: #5f5f5f;
  border: 1.5px solid #2d2d2d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #858585;
}
