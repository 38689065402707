.ant-tabs-content {
  height: 100%;
}

.ant-upload.ant-upload-drag.withFiles {
  height: 50%;
}

.ant-image-selected {
  border: 2px solid #177ddc;
  border-radius: 3px;
}

.ant-upload-list {
  overflow-y: auto;
  min-height: 50%;
  max-height: 50%;
}

/* .ant-upload-list {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 94%;
    width: 98%;
    overflow-y: auto;
    padding-bottom: 15px;
    z-index:0;
} */
